import * as functions from 'partials/functions.js';

// Pour faire du smooth scroll vers un élément. Commencez par l'instancier avec un parent au chargement.
// Puis, quand c'est le temps de scroller, appelez monInstance.scrollTo().
function SmoothScroller( args ) {
	let that = this;

	args = args || {};
	// Si aucun parent n'est trouvé
	args.parent = args.parent || window;

	args.scrollToOptions = args.scrollToOptions || {};
	// Si la différence entre la cible et le scroll actuel est plus petite que ignoreRange, on ne fera rien.
	args.ignoreRange = args.ignoreRange || 30;
	// En principe ça devrait pas arriver, mais on met fin au scroll si jamais les évènements sont lancés trop de fois.
	args.maxIterations = args.maxIterations || 20;
	args.parentIsFixed = args.parentIsFixed || false;

	that.args = args;

	that.init = function() {
		that.parent = that.args.parent;

		if( ! that.parent ) {
			return;
		}

		// On check dans quelles directions l'élément est scrollable.
		(function() {
			if( that.parent == window ) {
				that.parent.scrollable = {
					x: true,
					y: true,
				};
			} else {
				let parentCompStyle = window.getComputedStyle( that.parent );
				let overflowX = parentCompStyle.getPropertyValue( 'overflow-x' );
				let overflowY = parentCompStyle.getPropertyValue( 'overflow-y' );

				that.parent.scrollable = {};

				that.parent.scrollable.x = ( 'auto' == overflowX || 'scroll' == overflowX );
				that.parent.scrollable.y = ( 'auto' == overflowY || 'scroll' == overflowY );
			}
		}) ();

		if( that.parent == window ) {
			document.documentElement.classList.add( 'has-smooth-scroller' );
		} else {
			that.parent.classList.add( 'has-smooth-scroller' );
		}

		that.target = null;

		that.destination = {
			left: 0,
			top: 0,
		};

		that.requestedOffset = {};
		that.offset = {};
		that.updateOffset();

		that.align = {};

		that.behavior = 'smooth';

		that.okToScroll = true;
		that.scrolling = false;
		that.iterations = 0;
		that.monitorScrollEvents = [ 'lazyloaded', 'scrollintervalafter', 'load' ];
		that.monitorManualScrollEvents = [ 'touchstart', 'mousedown', 'mousewheel' ];

		// Un rectangle rouge qui flashe pour montrer la cible
		if( that.args.debug ) {
			that.debugRectDestination = document.createElement( 'div' );
			that.debugRectDestination.classList.add('smooth-scroller-debug-rectangle');

			that.debugRectDestination.style.position = 'absolute';
			that.debugRectDestination.style.outline = '1px solid red';
			that.debugRectDestination.style.zIndex = 9000;
			that.debugRectDestination.style.pointerEvents = 'none';
			that.debugRectDestination.style.color = 'red';
			that.debugRectDestination.style.padding = '3px';
			that.debugRectDestination.innerHTML = 'destination';

			that.debugRectPosition = that.debugRectDestination.cloneNode();
			that.debugRectPosition.style.outline = '2px solid green';
			that.debugRectPosition.style.color = 'green';
			that.debugRectPosition.style.zIndex = 8990;
			that.debugRectPosition.innerHTML = 'position';

			that.debugRectParent = that.debugRectDestination.cloneNode();
			that.debugRectParent.style.outline = '2px dashed cyan';
			that.debugRectParent.style.color = 'cyan';
			that.debugRectParent.style.zIndex = 8980;
			that.debugRectParent.innerHTML = 'parent';

			that.debugRectPosition.classList.add('smooth-scroller-debug-rectangle-position');
			that.debugRectDestination.classList.add('smooth-scroller-debug-rectangle-destination');
			that.debugRectParent.classList.add('smooth-scroller-debug-rectangle-parent');
		}
	};

	that.updateOffset = function() {
		that.requestedOffset.x = that.requestedOffset.x || 0;
		that.requestedOffset.y = that.requestedOffset.y || 0;

		if( that.offset.x != that.requestedOffset.x || that.offset.y != that.requestedOffset.y ) {
			that.offset = Object.assign( that.requestedOffset );

			if( window == that.parent ) {
				that.offset.x -= window.fixedElWidth;
				that.offset.y -= window.fixedElHeight;
			}
		}

		if( that.args.debug ) {
			console.log( "SmoothScroller.updateOffset: requestedOffset, offset" );
			console.log( that.requestedOffset, that.offset );
		}
	};

	// Création du rectangle imaginaire qui correspond à la position de l'objet dans le scroll
	// Dans le cas d'un scroll de window, l'offset correspond à fixedElHeight.
	// Si le parent n'est PAS window, alors la position correspond exactement au rect.
	that.updatePosition = function( positionTarget, positionParent ) {
		if( that.args.debug ) {
			console.log( "SmoothScroller.updatePosition: updating position of target versus parent:" );
			console.log( positionTarget, positionParent );
		}

		if( that.parent == window ) {
			positionTarget.position = {};

			if( window == positionTarget ) {
				positionTarget.position = window.curScroll;
			} else {
				if( window == positionParent && window == that.parent ) {
					positionTarget.position.top = that.target.rect.top + window.curScroll.top;
					positionTarget.position.left = that.target.rect.left + window.curScroll.left;
				} else {
					positionTarget.position.top = positionTarget.offsetTop;
					positionTarget.position.left = positionTarget.offsetLeft;
				}
			}

			// Calcul des autres côtés
			positionTarget.position.bottom = positionTarget.position.top + that.target.rect.height;
			positionTarget.position.right = positionTarget.position.left + that.target.rect.width;
		} else {
			positionTarget.position = positionTarget.rect;
		}

		if( that.args.debug ) {
			console.log( positionTarget.position );
		}
	};

	// La vraie destination vers laquelle on doit scroller en fonction de l'offset et de la position demandée.
	that.updateDestination = function() {
		if( that.args.debug ) {
			console.log( "SmoothScroller.updateDestination: align, offset, position:" );
			console.log( that.align, that.offset, that.target.position );
		}

		if( 'left' == that.align.x ) {
			that.destination.left = that.target.position.left + that.offset.x;
		} else if( 'right' == that.align.x ) {
			that.destination.left = that.target.position.right - that.parent.rect.width + that.offset.x;
		} else if( 'center' == that.align.x ) {
			that.destination.left = that.target.position.left + ( that.target.rect.width - that.parent.rect.width + that.offset.x ) / 2;
		}

		if( 'top' == that.align.y ) {
			that.destination.top = that.target.position.top + that.offset.y;
		} else if( 'bottom' == that.align.y ) {
			that.destination.top = that.target.position.bottom - that.parent.rect.height + that.offset.y;
		} else if( 'center' == that.align.y ) {
			that.destination.top = that.target.position.top + ( that.target.rect.height - that.parent.rect.height + that.offset.y ) / 2;
		}

		// Floor pour pas avoir de chiffre à virgule qui mélange tout
		that.destination.top = Math.floor( that.destination.top );
		that.destination.left = Math.floor( that.destination.left );

		// Si le parent est pas scrollable dans une direction, on met la destination à 0 pour pas causer de bugs
		if( ! that.parent.scrollable.x ) {
			that.destination.left = 0;
		}

		if( ! that.parent.scrollable.y ) {
			that.destination.top = 0;
		}

		if( that.args.debug ) {
			console.log( "SmoothScroller.updateDestination: updated destination:" );
			console.log( that.destination );
		}
	};

	that.updateRects = function() {
		if( window == that.parent ) {
			// Si c'est le parent, on fonctionne avec le viewport et bounding client rect
			that.parent.rect = {
				width: window.vWidth,
				height: window.vHeight,
				top: 0,
				right: window.vWidth,
				bottom: window.vHeight,
				left: 0,
			};

			that.target.rect = that.target.getBoundingClientRect();
		} else {
			// Si c'est un div scrollable, ça va dépendre de si les deux ont un offsetParent identique
			that.parent.rect = {
				width: that.parent.clientWidth,
				height: that.parent.clientHeight,
			};

			if( that.args.debug ) {
				console.log( "SmoothScroller.updateRects: parent offset parent, target offset parent:" );
				console.log( that.parent.offsetParent, that.target.offsetParent );
			}

			if( that.parent.offsetParent == that.target.offsetParent ) {				
				that.parent.rect.top = that.parent.offsetTop;
				that.parent.rect.left = that.parent.offsetTop;
			} else {
				that.parent.rect.top = 0;
				that.parent.rect.left = 0;
			}

			that.target.rect = {
				width: that.target.offsetWidth,
				height: that.target.offsetHeight,
				top: that.target.offsetTop,
				left: that.target.offsetLeft,
			};

			// Maintenant qu'on a les dimensions de base, on est capable de calculer le bottom/right
			that.parent.rect.right = that.parent.rect.left + that.parent.rect.width;
			that.parent.rect.bottom = that.parent.rect.top + that.parent.rect.height;

			that.target.rect.right = that.target.rect.left + that.target.rect.width;
			that.target.rect.bottom = that.target.rect.top + that.target.rect.height;
		}

		if( that.args.debug ) {
			console.log( "SmoothScroller.updateRects: parent, parent rect:" );
			console.log( that.parent, that.parent.rect );
			console.log( "SmoothScroller.updateRects: target, target rect:" );
			console.log( that.target, that.target.rect );
		}
	};

	that.updatePositions = function() {
		that.updatePosition( that.parent, window );
		that.updatePosition( that.target, that.parent );
	};

	that.updateOkToScroll = function() {
		// Si true, on est assez loin pour scroller.
		that.okToScroll = (
			(
				that.target.position.top > that.parent.position.top + args.ignoreRange
				|| that.target.position.top < that.parent.position.top - args.ignoreRange
			)
			|| (
				that.target.position.left > that.parent.position.left + args.ignoreRange
				|| that.target.position.left < that.parent.position.left - args.ignoreRange
			)
		);

		if( that.args.debug ) {
			console.log( "SmoothScroller.updateOkToScroll: updated okToScroll to:" );
			console.log( that.okToScroll );
		}
	};

	that.updateEverything = function() {
		if( that.args.debug ) console.log( "SmoothScroller.updateEverything: updating everything..." );

		// Au cas où le header aurait changé de taille en scrollant
		that.updateOffset();
		that.updateRects();
		// On base nos calculs sur les positions actuelles du parent et de la cible.
		that.updatePositions();

		// On combine l'alignement (haut/milieu/bas) et les positions pour savoir à quel pixel exactement on doit scroller
		that.updateDestination();
		// Maintenant qu'on connaît notre destination exacte, est-ce qu'on est assez loin pour scroller?
		that.updateOkToScroll();

		if( that.args.debug ) {
			that.updateDebugRects();
		}
	};

	that.monitorManualScrollListener = function( event ) {
		if( that.args.debug ) {
			console.log( "SmoothScroller.monitorManualScrollListener: User scrolled manually with following event. Ending scroll." );
			console.log( event );
		}

		// Annulation des écouteurs
		that.monitorManualScrollEvents.forEach( function( eventName )  {
			window.removeEventListener( eventName, that.monitorManualScrollListener );
		} );

		that.endScroll();
	};

	that.monitorScrollListener = function( event ) {
		if( that.args.debug ) console.log( "SmoothScroller.monitorScrollListener: event '" + event.type + "' occurred." );

		if( that.scrolling ) {
			let oldDestination = that.destination;
			that.updateEverything();

			if( oldDestination.left != that.destination.left || oldDestination.top != that.destination.top ) {
				// La destination a changé en cours de route. On commande un nouveau scroll.
				that.doScroll();
			} else {
				let curScroll;

				if( window == that.parent ) {
					curScroll = window.curScroll;
				} else {
					curScroll = {
						left: that.parent.scrollLeft,
						top: that.parent.scrollTop,
					};
				}

				if(
					( ! that.parent.scrollable.x || curScroll.left == that.destination.left )
					&& ( ! that.parent.scrollable.y || curScroll.top == that.destination.top )
				) {
					// Scroll terminé!
					that.endScroll();
				} else {
					++ that.iterations;

					if( that.args.debug ) {
						console.log( "SmoothScroller.monitorScrollListener: Continuing scroll... ( " + that.iterations + " / " + that.args.maxIterations + " ) iterations. destination, curScroll" );
							console.log( that.destination, curScroll );
					}

					// D'une façon ou d'une autre, on s'est jamais rendu au bout du scroll. On met fin au scroll à un moment donné pareil.
					if( that.args.maxIterations <= that.iterations ) {
						if( that.args.debug ) {
							console.log("SmoothScroller.monitorScrollListener: Still not there after " + that.args.maxIterations + " occurrences. Aborting scroll. destination, curScroll");
							console.log( that.destination, curScroll );
						}

						that.endScroll();
					}
				}
			}
		}
	};

	that.monitorScroll = function() {
		that.monitorScrollEvents.forEach( function( eventName ) {
			window.addEventListener( eventName, that.monitorScrollListener );

			if( that.args.debug ) console.log( "SmoothScroller.monitorScroll: listener added for event '" + eventName + "'." );
		} );

		// On annule tout si l'utilisateur essaie de scroller manuellement.
		that.monitorManualScrollEvents.forEach( function( eventName )  {
			window.addEventListener( eventName, that.monitorManualScrollListener );
		} );
	};

	that.endScroll = function() {
		if( that.args.debug ) console.log( "SmoothScroller.monitorScroll: Scrolling ended!" );

		let endEvent = new Event( 'smoothscrollend' );
		that.scrolling = false;

		// On change le hash si nécessaire
		if( that.setHash && that.target.id ) {
			functions.changeHashWithoutScrolling( that.target.id, that.historyStateObj, that.historyAction );
		}

		// On lance l'évènement de scroll fini.
		that.parent.dispatchEvent( endEvent );

		// On annule tous les écouteurs.
		that.monitorScrollEvents.forEach( function( eventName ) {
			window.removeEventListener( eventName, that.monitorScrollListener );
		} );
	};

	that.doScroll = function() {
		let scrollOptions = {
			behavior: that.behavior,
			left: that.destination.left,
			top: that.destination.top,
		};

		if( that.args.debug ) {
			console.log( "SmoothScroller.doScroll: Starting scroll - parent, options:" );
			console.log( that.parent, scrollOptions );
		}

		that.parent.scrollTo( scrollOptions );
		that.scrolling = true;
	};

	that.maybeDoScroll = function() {
		that.updateEverything();

		if( that.okToScroll ) {
			let startEvent = new Event( 'smoothscrollstart' );

			that.parent.dispatchEvent( startEvent );
			// Tout au long du scroll, ça va vérifier si le scroll est fini et mettre à jour la destination (au cas où elle change à cause de modifications de tailles d'éléments)
			that.monitorScroll();
			that.doScroll();
		}
	};


	// args, c'est les arguments de cette méthode. Ne pas confondre avec that.args, les arguments de l'objet.
	that.scrollTo = function( args ) {
		if( ! that.scrolling ) {
			if( args && ! args.target ) {
				args = { target: args };
			}

			args.target = args.target ? functions.getElementByHash( args.target ) : null;
			that.target = args.target;
			that.iterations = 0;

			if( that.args.debug ) {
				console.log('SmoothScroller.scrollTo: args :');
				console.log( args );
			}

			if( args.target ) {
				that.align = args.align = args.align || {};
				that.align.x = args.align.x || 'left';
				that.align.y = args.align.y || 'top';
				that.setHash = args.setHash || true;
				that.behavior = args.behavior || 'smooth';

				that.requestedOffset = args.offset || that.requestedOffset;

				// On peut envoyer un history.state et une action 'replace' à la place si on veut ne pas ajouter d'étape à l'historique.
				that.historyStateObj = args.historyStateObj || null;
				that.historyAction = args.historyAction || 'push';

				that.maybeDoScroll();
			}
		} else {
			if( that.args.debug ) {
				console.log( "SmoothScroller.scrollTo: Currently scrolling. Will not scroll." );
			}
		}
	};

	that.updateDebugRects = function() {
		console.log( "SmoothScroller.updateDebugRects : Using the following variables : destination, target.rect, parent.rect" );
		console.log( that.destination, that.target.rect, that.parent.rect );

		that.debugRectDestination.style.top = that.destination.top + 'px';
		that.debugRectDestination.style.left = that.destination.left + 'px';
		that.debugRectPosition.style.top = that.target.rect.top + 'px';
		that.debugRectPosition.style.left = that.target.rect.left + 'px';

		that.debugRectDestination.style.width =
			that.debugRectPosition.style.width =
			that.target.rect.width + 'px'
		;
		that.debugRectDestination.style.height =
			that.debugRectPosition.style.height =
			that.target.rect.height + 'px'
		;
		
		that.debugRectParent.style.top = that.parent.rect.top + 'px';
		that.debugRectParent.style.left = that.parent.rect.left + 'px';
		that.debugRectParent.style.width = that.parent.rect.width + 'px';
		that.debugRectParent.style.height = that.parent.rect.height + 'px';

		if( window == that.parent ) {
			document.body.append( that.debugRectDestination );
			document.body.append( that.debugRectPosition );
			document.body.append( that.debugRectParent );
		} else {
			that.parent.style.position = 'relative';
			that.parent.append( that.debugRectDestination );
			that.parent.append( that.debugRectPosition );
			that.parent.append( that.debugRectParent );
		}
	};

	that.init();

	if( ! that.parent ) {
		console.error( "SmoothScroller: You must specify a parent." );
		return [];
	} else {
		that.parent._SmoothScroller = that;
	}

}

// Un raccourci pour ceux qui utilisent la vieille fonction
export function smoothScrollTo( args ) {
	if( window.smoothScroller ) {
		window.smoothScroller.scrollTo( args );
	}
}

// Pour trouver le smooth scroller le plus proche d'un élément arbitraire
export function getSmoothScroller( element ) {
	let closestParent = element.closest( '.has-smooth-scroller' );

	if( closestParent ) {
		if( closestParent != document.documentElement ) {
			return closestParent._SmoothScroller;
		} else {
			return window._SmoothScroller;
		}
	} else {
		return null;
	}
}

export default SmoothScroller;