function FakeSticky( args ) {
	let that = this;

	args = args || {};
	// Si aucun parent n'est trouvé
	args.parentSel = args.parentSel || '.fake-sticky-parent';
	// Array de media queries à surveiller. Ex. : [ '(min-width: 400px)', '(min-width: 700px)' ]
	// null = utiliser le resize à la place.
	args.mediaQueries = args.mediaQueries || null;

	// En dehors de ces dimensions, le FakeSticky n'a aucun effet.
	args.minWidth = args.minWidth || null;
	args.maxWidth = args.maxWidth || null;
	args.minHeight = args.minHeight || null;
	args.maxHeight = args.maxHeight || null;

	args.debug = args.debug || false;

	this.args = args;

	let oppositeDirections = {
		top: 'bottom',
		right: 'left',
		bottom: 'top',
		left: 'right',
	};

	let resetPaddingObj = {
		paddingTop: '',
		paddingRight: '',
		paddingBottom: '',
		paddingLeft: '',
	};

	this.init = function() {
		that.element = that.args.element || null;

		if( ! that.element ) {
			return;
		}

		if( that.args.parentSel ) {
			that.parent = that.element.closest( that.args.parentSel );
		}

		if( ! that.parent ) {
			that.parent = document.body;
		}

		that.addEventListeners();

		that.maybeRefresh();
	};

	this.refreshMeasures = function() {
		that.getStyles();
		that.getDirections();
		that.getHeight();
	};

	this.getStyles = function() {
		that.styles = window.getComputedStyle( that.element );
	};

	this.getDirections = function() {
		that.directions = [
			{
				key: 'top',
				value: parseFloat( that.styles.getPropertyValue( 'top' ) ),
			},
			{
				key: 'right',
				value: parseFloat( that.styles.getPropertyValue( 'right' ) ),
			},
			{
				key: 'bottom',
				value: parseFloat( that.styles.getPropertyValue( 'bottom' ) ),
			},
			{
				key: 'left',
				value: parseFloat( that.styles.getPropertyValue( 'left' ) ),
			},
		];

		// On met les directions en ordre du plus grand au plus petit
		that.directions.sort( function( a, b ) {
			return b.value - a.value;
		} );

		// L'inverse de la plus grande valeur, c'est le côté où est placé l'élément. Par exemple, un gros top signifie que l'élément est placé en bas.

		that.direction = oppositeDirections[ that.directions[0].key ];

		if( that.args.debug ) {
			console.log( "fake-sticky: Updated directions." );
			console.log( that.directions );
		}
	};

	this.getHeight = function() {
		that.height = that.element.offsetHeight;
		if( that.args.debug ) console.log( "fake-sticky: Updated height: " + that.height );
	};

	this.resetPadding = function() {
		let styleObject = {};
		Object.assign( styleObject, resetPaddingObj );
		Object.assign( that.parent.style, styleObject );
	};

	this.setPadding = function() {
		// Copie du reset
		let styleObject = {};
		Object.assign( styleObject, resetPaddingObj );

		let paddingProperty = that.getPaddingProperty( that.direction );

		styleObject[ paddingProperty ] = that.height + 'px';

		// Maintenant qu'on a préparé un objet qui reset les mauvaises + set la bonne, on l'applique à l'élément tout d'un coup
		Object.assign( that.parent.style, styleObject );

		if( that.args.debug ) console.log( "fake-sticky: Updated padding: " + paddingProperty + " = " + that.height + 'px' );
	};

	// On lui donne 'top' et elle retourne 'paddingTop'.
	this.getPaddingProperty = function( thisDir ) {
		let cssPropertyName = 'padding';
		// Première lettre en majuscules
		cssPropertyName += thisDir.charAt(0).toUpperCase();
		// Reste du mot
		cssPropertyName += thisDir.slice(1);

		return cssPropertyName;
	};

	this.addEventListeners = function() {
		if( null === that.args.mediaQueries ) {
			window.addEventListener( 'resize', function() {
				that.maybeRefresh();
			} );
		} else if( Array.isArray( that.args.mediaQueries ) ) {
			that.args.mediaQueries.forEach( function( thisMedia ) {
				let mqList = window.matchMedia( thisMedia );

				mqList.addListener( function() {
					that.maybeRefresh();
				} );
			} );
		} else if( that.args.debug ) {
			console.log( "fake-sticky: The mediaQueries argument must be null or an array. Value received:" );
			console.log( that.args.mediaQueries );
		}
	};

	this.maybeRefresh = function() {
		if(
			   ( null === that.args.minWidth || window.vWidth >= that.args.minWidth )
			&& ( null === that.args.maxWidth || window.vWidth <= that.args.maxWidth )
			&& ( null === that.args.minHeight || window.vHeight >= that.args.minHeight )
			&& ( null === that.args.maxHeight || window.vHeight <= that.args.maxHeight )
		) {
			that.refreshMeasures();
			that.setPadding();
		} else {
			that.resetPadding();
		}
	};


	this.init();

	if( ! this.element ) {
		console.error( "fake-sticky: no element given. Aborting." );
		return [];
	} else {
		this.element._FakeSticky = this;
	}

}

export default FakeSticky;