let testInput = document.createElement('input');
testInput.type = 'number';

try {
	testInput.stepUp();
} catch( e ) {
	// stepUp n'existe pas! Polyfillons.

	HTMLInputElement.prototype.stepChange = function( nbSteps, multiplier ) {
		nbSteps = nbSteps || 1;
		multiplier = multiplier || 1;

		let that = this;
		let type = that.getAttribute('type');
		let value = that.value;
		let step = this.getAttribute('step') || 1;
		let min = this.getAttribute('min') || 0;
		let max = this.getAttribute('max') || null;

		if( 'number' == type ) {
			value = Number( value );
			step = Number( step );
			min = Number( min );
			max = Number( max );
			nbSteps = Number( nbSteps );
			multiplier = Number( multiplier );

			if( that.value || 0 === that.value ) {
				let totalChange = nbSteps * step * multiplier;
				let newVal = value + totalChange;

				if( 'undefined' != typeof min && null !== min && newVal < min ) {
					newVal = min;
				}

				if( 'undefined' != typeof min && null !== max && newVal > max ) {
					newVal = max;
				}

				// console.log(newVal);

				that.value = newVal;
			} else {
				that.value = step * multiplier;
			}
		}
	};

	HTMLInputElement.prototype.stepUp = function( nbSteps ) {
		this.stepChange( nbSteps, 1 );
	};

	HTMLInputElement.prototype.stepDown = function( nbSteps ) {
		this.stepChange( nbSteps, -1 );
	};
}