/* global google */
function GoogleMap( args ) {
	if( 'undefined' != typeof google && 'undefined' != typeof google.maps ) {
		let that = this;
		args = args || {};

		args.center = args.center || null;
		args.zoom = args.zoom || 16;
		args.markerSel = args.markerSel || '.marker';
		args.styles = args.styles || null;
		args.navigationControl = args.navigationControl || true;
		args.mapTypeControl = args.mapTypeControl || false;
		args.zoomControl = args.zoomControl || true;
		args.streetViewControl = args.streetViewControl || false;
		args.scaleControl = args.scaleControl || false;
		args.fullscreenControl = args.fullscreenControl || false;
		args.gestureHandling = args.gestureHandling || 'auto';
		args.mapTypeId = args.mapTypeId || google.maps.MapTypeId.ROADMAP;
		args.styles = args.styles || [];
		args.markerIconUrl = args.markerIconUrl || window.bonesGlobals.themeDir + '/library/images/map-marker.svg';
		args.markerIconWidth = args.markerIconWidth || 56;
		args.markerIconHeight = args.markerIconHeight || 58;
		args.onlyOneInfoWindow = args.onlyOneInfoWindow || true;

		this.args = args;

		this.init = function() {
			that.map = null;
			that.mapParent = that.args.mapParent;
			that.markerEls = that.mapParent.querySelectorAll( args.markerSel );
			that.markers = [];
			that.openInfoWindows = [];
			that.markersToOpenOnLoad = [];

			// Pas de centre par défaut ? On choisit le premier marqueur.
			if( ! that.args.center && that.markerEls.length > 0 ) {
				that.args.center = {
					lat: parseFloat( that.markerEls[0].dataset.lat ),
					lng: parseFloat( that.markerEls[0].dataset.lng ),
				};
			}

			if( that.args.center ) {
				that.args.center = new google.maps.LatLng( that.args.center );
			} else {
				that.args.center = new google.maps.latLng({ lat: 0, lng: 0 });
			}

			that.render();
			that.addMarkers();
			that.center();
			that.openDefaultMarkers();
		};

		this.render = function() {
			let mapArgs = {
				zoom: that.args.zoom,
				center: that.args.center,
				navigationControl: that.args.navigationControl,
				mapTypeControl: that.args.mapTypeControl,
				zoomControl: that.args.zoomControl,
				streetViewControl: that.args.streetViewControl,
				scaleControl: that.args.scaleControl,
				fullscreenControl: that.args.fullscreenControl,
				gestureHandling: that.args.gestureHandling,
				mapTypeId: that.args.mapTypeId,
				styles: that.args.styles,
			};

			that.map = new google.maps.Map( that.mapParent, mapArgs );

			//Quand la map a complètement fini de charger, ajout d'une classe
			google.maps.event.addListenerOnce( that.map, 'idle', function() {
				that.args.mapParent.classList.add( 'map-loaded' );
			});
		};

		this.addMarkers = function() {
			that.markerEls.forEach( function( thisEl ) {
				that.addMarker( thisEl );
			} );
		};

		this.addMarker = function( markerEl ) {
			let markerArgs = {};
			let markerIconUrl;
			let markerIconWidth;
			let markerIconHeight;
			let marker;
			let iwContent = markerEl.innerHTML.trim();

			markerIconUrl = markerEl.dataset.icon || that.args.markerIconUrl;
			markerIconWidth = markerEl.dataset.iconWidth || that.args.markerIconWidth;
			markerIconWidth = parseFloat( markerIconWidth );
			markerIconHeight = markerEl.dataset.iconHeight || that.args.markerIconHeight;
			markerIconHeight = parseFloat( markerIconHeight );

			markerArgs.position = new google.maps.LatLng(
				parseFloat( markerEl.dataset.lat ),
				parseFloat( markerEl.dataset.lng )
			);

			markerArgs.map = that.map;

			if( markerIconUrl ) {
				if( /\.svg(#.*)?$/i.test( markerIconUrl ) ) {
					markerArgs.icon = {
						url: markerIconUrl,
						scaledSize: new google.maps.Size( markerIconWidth, markerIconHeight ),
					};
				} else {
					markerArgs.icon = markerIconUrl;
				}
			}

			markerArgs.styles = that.args.styles || null;

			marker = new google.maps.Marker( markerArgs );

			if( iwContent ) {
				let openOnLoad = markerEl.dataset.openOnLoad ? true : false;

				if( 'false' == openOnLoad ) {
					openOnLoad = false;
				}

				marker.infoWindow = new google.maps.InfoWindow({
					content: iwContent,
				});

				if( openOnLoad ) {
					that.markersToOpenOnLoad.push( marker );
				}

				google.maps.event.addListener( marker, 'click', function() {
					// Fermeture des autres InfoWindow.
					if( that.args.onlyOneInfoWindow && that.openInfoWindows.length ) {
						that.openInfoWindows.forEach( function( thisWin, index ) {
							thisWin.close();
							delete that.openInfoWindows[index];
						} );
					}

					that.openInfoWindows.push( marker.infoWindow );
					marker.infoWindow.open( that.map, marker );
				} );
			}

			that.markers.push( marker );
			return marker;
		};

		this.center = function() {
			let bounds = new google.maps.LatLngBounds();

			that.markers.forEach( function( thisMarker ) {
				let latLng = new google.maps.LatLng( thisMarker.position.lat(), thisMarker.position.lng() );
				bounds.extend( latLng );
			} );

			if( 1 == that.markers.length ) {
				that.map.setCenter( bounds.getCenter() );
				that.map.setZoom( that.args.zoom );
			} else {
				that.map.fitBounds( bounds );
			}
		};

		this.openDefaultMarkers = function() {
			google.maps.event.addListenerOnce( that.map, 'idle', function() {
				that.markersToOpenOnLoad.forEach( function( thisMarker ) {
					if( thisMarker.infoWindow ) {
						thisMarker.infoWindow.open( that.map, thisMarker );
					}
				} );
			} );
		};

		this.init();
	} else {
		console.error( "GoogleMap : Google Maps API script must be enqueued for this page. Check functions.php > page_has_map()." );
	}
}

export default GoogleMap;