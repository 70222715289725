// On peut se hooker sur divers divers évènenements d'infinite scroll, dans lesquels event.target = le conteneur
// event.target._infiniteScrollSimple = instance si vous l'avez perdue
// thisEl.addEventListener( 'issupdaterequested' ) - bouton cliqué, même pas sûr s'il va y avoir update
// thisEl.addEventListener( 'issbeforeupdate' ) - après call AJAX, enfants parsés, pas ajoutés au DOM
// thisEl.addEventListener( 'issafterupdate' ) - après call AJAX, enfants ajoutés au DOM
// thisEl.addEventListener( 'issnomore' ) - après call AJAX, enfants parsés, pas ajoutés au DOM, c'est la dernière page
// unItem.addEventListener( 'issitemanimated' ) - pour chaque élément animé


// Utiliser ce polyfill si besoin d'IE 11
// import 'unfetch/polyfill';
// Babel a besoin de ça pour les fonctions async
import "regenerator-runtime/runtime";
// Pour les divers sizes d'images
import imgCheck from "img-check.js";

function InfiniteScrollSimple( args ) {
	let that = this;

	let updateRequestedEvent = new Event( 'issupdaterequested' );
	let beforeUpdateEvent = new Event( 'issbeforeupdate' );
	let afterUpdateEvent = new Event( 'issafterupdate' );
	let noMoreEvent = new Event( 'issnomore' );
	let itemAnimatedEvent = new Event( 'issitemanimated' );

	args = args || {};

	args.container = args.container || null;
	args.instanceIdAttr = args.instanceIdAttr || 'data-iss-instance-id';
	args.loadingClass = args.loadingClass || 'iss-loading';
	args.noMoreClass = args.noMoreClass || 'iss-no-more';
	args.itemSel = args.itemSel || '.iss-item';
	args.buttonSel = args.buttonSel || '.iss-button';
	args.animatingInClass = args.animatingInClass || 'iss-animating-in';
	args.animatedInClass = args.animatedInClass || 'iss-animated-in';
	args.containerInnerSel = args.containerInnerSel || '.iss-container-inner';
	args.animate = args.animate || false;
	args.useInner = args.useInner || true;
	args.debug = args.debug || false;

	this.args = args;

	this.init = function() {
		if( that.args.container && that.args.container instanceof HTMLElement ) {
			that.container = that.args.container;
			if( that.args.debug ) {
				console.log( "InfiniteScrollSimple: container set to:" );
				console.log( that.container );
			}
		} else {
			console.error( "InfiniteScrollSimple: args.container must be set and be an HTMLElement." );
			return;
		}

		that.instanceId = that.container.getAttribute( that.args.instanceIdAttr ) || null;

		if( that.args.debug && that.instanceId ) {
			console.warn( "InfiniteScrollSimple: You set an instance ID of " + that.instanceId + " to your container. This makes the script heavier to run. Only do this if your page has several different instances." );
			console.log( "InfiniteScrollSimple: Make sure your instance ID is set to every one of those elements: container, button, iss-element." );
		}

		that.button = that.getKeyElement( 'button' );

		if( that.args.useInner ) {
			that.containerInner = that.getKeyElement( 'containerInner' );
			that.appendEl = that.containerInner;
		} else {
			that.appendEl = that.container;
		}

		if( that.args.debug ) {
			if( that.args.useInner ) {
				console.log( "InfiniteScrollSimple: Using an inner container." );
				console.log( "InfiniteScrollSimple: containerInner set to:" );
				console.log( that.containerInner );
			}
			console.log( "InfiniteScrollSimple: button set to:" );
			console.log( that.button );
		}

		that.domParser = new DOMParser();
		that.loading = false;
		that.noMore = false;

		that.addEventListeners();
	};

	this.addInstanceIdTo = function( inputSel ) {
		let outputSel = inputSel;

		if( that.instanceId ) {
			outputSel += '[' + that.args.instanceIdAttr + '="' + that.instanceId + '"]';
		}

		return outputSel;
	};

	this.getKeyElement = function( keyElType, source, initial ) {
		source = source || document;
		initial = 'undefined' != typeof initial ? initial : true;

		let keyEl = null;
		let keyEls = null;
		let theSel;

		switch( keyElType ) {
			case 'containerInner':
				theSel = that.args.containerInnerSel;
				break;
			case 'button':
				theSel = that.args.buttonSel;
				break;
		}

		theSel = that.addInstanceIdTo( theSel );
		keyEls = source.querySelectorAll( theSel );

		if( keyEls.length <= 0 ) {
			if( initial ) {
				if( that.args.debug ) console.error( "InfiniteScrollSimple: key element '" + keyElType + "' not found with selector '" + theSel + "'. The script will not work." );
			}
		} else if( keyEls.length > 1 ) {
			if( that.args.debug ) {
				console.log( "InfiniteScrollSimple: key element '" + keyElType + "' found in more than one copy with selector '" + theSel + "'. If you need several instances, use an instance ID and make sure each is unique. Elements found:" );
				console.log( keyEls );
			}
		} else {
			keyEl = keyEls[0];
		}

		return keyEl;
	};

	this.loadingStart = function() {
		that.loading = true;
		that.container.classList.add( that.args.loadingClass );

		if( that.button._loadingButton ) {
			that.button._loadingButton.loadingStart();
		} else {
			that.button.disabled = true;
		}
	};

	this.loadingEnd = function() {
		that.loading = false;
		that.container.classList.remove( that.args.loadingClass );

		if( that.button._loadingButton ) {
			that.button._loadingButton.loadingEnd();
		} else {
			that.button.disabled = false;
		}
	};

	this.getNextPage = async function() {
		let ajaxUrl = new URL( that.nextPageUrl );
		let fetchOptions = {
			headers: {
				'X-Requested-With': 'XmlHttpRequest'
			},
			method: 'GET',
		};

		that.loadingStart();
		if( that.args.debug ) console.log( "InfiniteScrollSimple: Getting next page from URL '" + ajaxUrl.href + "'" );

		let response = await fetch( ajaxUrl.href, fetchOptions );
		let responseText = response.text().then( function( responseText ) {
			that.loadingEnd();
			return responseText;
		} );

		return responseText;
	};

	// On va chercher la prochaine page avec getNextPage, puis on fait des opérations de HTML pour appliquer les données reçues à la page actuelle
	this.addNextPage = function() {
		that.getNextPage().then( function( nextPageText ) {
			if( that.args.debug ) {
				console.log( "InfiniteScrollSimple: Obtained next page as text:" );
				console.log( nextPageText );
			}

			let docFrag = new DocumentFragment();

			// On va chercher uniquement les éléments qui sont des enfants de cette instance d'infinite scroll. On modifie l'URL du bouton existant.
			that.extractPiecesFromText( nextPageText );

			if( that.args.debug ) {
				console.log( "InfiniteScrollSimple: Parsed elements:" );
				console.log( that.nextPageItems );
			}

			that.container.dispatchEvent( beforeUpdateEvent );

			if( that.nextPageButton ) {
				// Il y a un bouton sur l'autre page. Il reste des pages.
				that.button.href = that.nextPageButton.href;
				if( that.args.debug ) console.log( "InfiniteScrollSimple: Set next page button href to '" + that.button.href + "'" );
			} else {
				if( that.args.debug ) console.log( "InfiniteScrollSimple: That was the last page." );
				that.noMore = true;
				that.container.classList.add( that.args.noMoreClass );
				that.container.dispatchEvent( noMoreEvent );
			}

			that.nextPageItems.forEach( function( thisEl ) {
				if( that.args.animate ) {
					thisEl.classList.add( that.args.animatingInClass );
				}

				docFrag.appendChild( thisEl );
			} );

			// Maintenant qu'on a rempli le docFrag, on peut l'ajouter au container.
			that.appendEl.appendChild( docFrag );


			// Mettons les bons src aux images (images lazy - src appliqués plus tard)
			if( 'function' == typeof imgCheck ) {
				imgCheck();
			}

			if( 'function' == typeof window.siteLazyLoad.update ) {
				window.siteLazyLoad.update();
			}

			// Animations. On force le reflow sinon la première s'animera jamais
			if( that.args.animate && that.nextPageItems ) {
				that.nextPageItems[0].offsetHeight;
				that.animateNewItems();
			}

			that.container.dispatchEvent( afterUpdateEvent );
		} );
	};

	this.extractPiecesFromText = function( sourceText ) {
		let htmlDoc = that.domParser.parseFromString( sourceText, 'text/html' );

		that.nextPageItems = htmlDoc.querySelectorAll(
			that.addInstanceIdTo( that.args.itemSel ) 
		);

		that.nextPageButton = that.getKeyElement( 'button', htmlDoc, false );
	};

	this.animateNewItems = function() {
		let prevEl = null;

		// if( that.args.debug ) {
		// 	console.log( "InfiniteScrollSimple: Adding animation events to items:" );
		// 	console.log( that.nextPageItems );
		// }

		that.nextPageItems.forEach( function( thisEl ) {
			// Évènement custom pour dire que l'élément a fini d'apparaître
			thisEl.addEventListener( 'transitionend', function thisElAnimated( event ) {
				if( event.target.matches( that.args.itemSel ) ) {
					// if( that.args.debug ) {
					// 	console.log( "InfiniteScrollSimple: transitionend fired on:" );
					// 	console.log( event.target );
					// }

					thisEl.dispatchEvent( itemAnimatedEvent, thisEl );
					thisEl.removeEventListener( 'transitionend', thisElAnimated );
				}
			} );

			if( prevEl ) {
				// On active tous sauf le premier quand le précédent a fini d'apparaître
				prevEl.addEventListener( 'issitemanimated', function prevElAnimated( event ) {
					// if( that.args.debug ) {
					// 	console.log( "InfiniteScrollSimple: issitemanimated fired on previous item. Animating item:" );
					// 	console.log( thisEl );
					// }

					thisEl.classList.add( that.args.animatedInClass );
					event.target.removeEventListener( 'issitemanimated', prevElAnimated );
				} );
			}

			prevEl = thisEl;
		} );

		// On fait animer le premier pour amorcer la chaîne
		that.nextPageItems[0].classList.add( that.args.animatedInClass );
	};

	this.addEventListeners = function() {
		that.container.addEventListener( 'click', function( event ) {
			let buttonClicked = event.target.closest( that.args.buttonSel );
			let isMouseLeft = ( 0 == event.which || 0 == event.button );

			that.container.dispatchEvent( updateRequestedEvent );

			if( buttonClicked && isMouseLeft ) {
				event.preventDefault();
				that.nextPageUrl = buttonClicked.href;

				if( that.args.debug ) {
					console.log( "_________________________________________________________________________________________________________________________________________________________________" );
					console.log( "InfiniteScrollSimple: Clicked button. Maybe adding next page." );
				}

				if( ! that.loading && ! that.noMore && ! buttonClicked.disabled ) {
					if( that.args.debug ) console.log( "InfiniteScrollSimple: Adding next page..." );
					that.addNextPage();
				}
			}
		} );
	};

	// Initialisation

	this.init();

	if( ! this.container ) {
		return [];
	} else {
		this.container._infiniteScrollSimple = this;
	}
}

export default InfiniteScrollSimple;