/*** Importations *********************************************************************************/

// Polyfills

import 'mdn-polyfills/NodeList.prototype.forEach';
import 'mdn-polyfills/Element.prototype.matches';
import 'mdn-polyfills/Element.prototype.closest';
import 'mdn-polyfills/CustomEvent';
import 'mdn-polyfills/Node.prototype.prepend';
import 'mdn-polyfills/Node.prototype.append';
import 'mdn-polyfills/Node.prototype.remove';
import 'mdn-polyfills/Object.assign';
import 'partials/stepup-stepdown-polyfill';

import 'classlist-polyfill';
// import 'unfetch/polyfill';
import 'focus-within-polyfill';
import objectFitImages from 'object-fit-images/dist/ofi.es-modules.js';
import smoothscroll from 'smoothscroll-polyfill';


// Libraries

// import InfiniteScroll from 'infinite-scroll';
// import fullpage from 'fullpage.js';
// import 'owl.carousel';
// import * as basicLightbox from 'basiclightbox/src/scripts/main.js';
// import { CountUp } from 'countup.js';
// import tippy from 'tippy.js/dist/tippy.esm.js';
// import flatpickr from 'flatpickr';
// import fr from "flatpickr/dist/l10n/fr.js";
// import { tns } from 'tiny-slider/src/tiny-slider.module.js';
// import GLightbox from 'glightbox/dist/js/glightbox.js';
// import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock/lib/bodyScrollLock.es6.js';
import Glide from '@glidejs/glide/dist/glide.esm.js';
import LazyLoad from 'vanilla-lazyload/dist/lazyload.esm.js';


// Scripts Nubee

// import initSliderOwl from 'partials/owl-carousel-nubee.js';
// import initFlatpickr from 'partials/init-flatpickr.js';
// import PageMenu from 'partials/page-menu.js';
// import toggleSearchForm from 'partials/toggle-search-form.js';
// import AjaxPopup from 'partials/ajax-popup.js'; // basicLightbox
// import NubeeLightbox from 'partials/nubee-lightbox.js'; // basicLightbox
// import SwipeFinger from 'partials/swipe-finger.js';
// import NumberInput from 'partials/number-input.js';
// import * as nubeeLoading from 'partials/nubee-loading.js';
// import Tabs from 'partials/tabs.js';
import * as functions from 'partials/functions.js';
import 'partials/scroll-interval.js';
import 'partials/target-blank-security.js';
import PrettyFileInput from 'partials/pretty-file-input.js';
import placeholderLabelUpdate from 'partials/placeholder-label.js';
import GLightboxNubee from 'partials/glightbox-nubee.js';
// import initSliderTiny from 'partials/tiny-slider-nubee.js';
import googleMapStyles from 'data/google-map-styles.js';
import GoogleMap from 'partials/google-map.js';
import MobileMenu from 'partials/mobile-menu.js';
import imgCheck from 'partials/img-check.js';
import FormValidation from 'partials/form-validation.js';
import InfiniteScrollSimple from 'partials/infinite-scroll-simple.js';
import LoadingButton from 'partials/loading-button.js';
import FakeSticky from 'partials/fake-sticky.js';
import MultiActionForm from 'partials/multi-action-form.js';

// On set toutes les variables globales et évènements qui les modifient ici
import * as globalVarFunctions from 'partials/global-variables.js';

(function() {
	/*** Application des polyfills ********************************************************************/

	(function() {
		objectFitImages();
		smoothscroll.polyfill();

		if( 'function' != typeof window.Event ) {
			window.Event = CustomEvent;
		}
	}) ();


	/*** Variables globales ***************************************************************************/
	// global-variables.js
	// Chaînes traduisibles et autres variables provenant de bones : window.bonesGlobals.i18n.monPlugin.nomDeChaine

	globalVarFunctions.setGlobalVariables();


	/*** Les liens avec ancre qui mènent vers la même page deviennent seulement des ancres *******************/
	// Requiert functions.urlsAreSamePage
	// Requiert functions.arrayAllEqual

	(function() {
		let linksWithHash = document.querySelectorAll('a[href*="#"]');

		linksWithHash.forEach( function( thisLink ) {
			if( functions.urlsAreSamePage({ urls: [ thisLink.href, window.location.href ] }) ) {
				thisLink.href = thisLink.hash;
			}
		} );
	}) ();


	/*** Défiler doucement les liens ancres *******************************************************************/
	// Requiert window.smoothScroller (smooth-scroller.js), initialisé dans global-variables.js
	// Ajuster la variable globale window.fixedElHeight

	(function() {
		if( window.smoothScroller ) {
			let smoothScrollLinks = document.querySelectorAll('a[href^="#"]:not([href="#"]):not(.no-smooth-scroll):not([target])');

			smoothScrollLinks.forEach( function( thisEl ) {
				if( functions.scriptsOk( thisEl ) ) {
					thisEl.addEventListener( 'click', function( event ) {
						if( 1 == event.which ) {
							event.preventDefault();
							window.smoothScroller.scrollTo({ target: thisEl.hash });
						}
					} );
				}
			});
		}
	}) ();


	/*** Ne rien faire au clic sur un lien vide (#) ************************************************************/

	(function() {
		document.addEventListener( 'click', function( event ) {
			if( 1 == event.which ) {
				let linkClicked = event.target.closest('a');
				let theHref = linkClicked ? linkClicked.getAttribute('href') : null;

				if( linkClicked && ( ! theHref || '#' == theHref ) && functions.scriptsOk( linkClicked ) ) {
					event.preventDefault();
				}
			}
		} );
	}) ();


	/*** Images et backgrounds différents selon la taille de l'écran ********************************/
	// Requiert img-check.js

	(function() {
		if( 'function' == typeof imgCheck ) {
			window.addEventListener( 'resize', imgCheck );
			imgCheck();
		}
	}) ();


	/*** Lazy load des images et backgrounds *******************************************************************/
	// Requiert vanilla-lazyload

	(function() {
		let loadedEvent = new Event( 'lazyloaded' );

		window.siteLazyLoad = new LazyLoad({
			elements_selector: '.lazy',
			class_loading: 'lazy-loading',
			class_loaded: 'lazy-loaded',
			class_error: 'lazy-error',
			// use_native: true,
			callback_loaded: function( element ) {
				element.dispatchEvent( loadedEvent );
			},
		});
	}) ();


	/*** Google Maps ******************************************************************************************/
	// Requiert google-map.js
	// Requiert data/google-map-styles.js (copier-coller votre style là-dedans!)

	(function() {
		let mapParents = document.querySelectorAll('.the-map');

		mapParents.forEach( function( thisParent ) {
			if( functions.scriptsOk( thisParent ) ) {
				new GoogleMap({
					mapParent: thisParent,
					styles: googleMapStyles,
					markerIconUrl: window.bonesGlobals.themeDir + '/library/images/map-marker.svg'
				});
			}
		} );
	}) ();


	/*** Antispam maison sur les commentaires ****************************************************************/
	// À moins d'avoir activé le JS, la validation PHP refusera le commentaire.

	(function() {
		let commentForms = document.querySelectorAll('#commentform');

		commentForms.forEach( function( thisForm ) {
			thisForm.addEventListener( 'submit', function() {
				let legitInput = document.createElement( 'input' );
				legitInput.setAttribute( 'type', 'hidden' );
				legitInput.setAttribute( 'name', 'is_legit' );
				legitInput.value = 1;

				thisForm.appendChild( legitInput );
			} );
		} );
	}) ();


	/*** Validation de formulaire ****************************************************************************/
	(function() {
		document.querySelectorAll( '.validate-form' ).forEach( function( thisForm ) {
			if( functions.scriptsOk( thisForm ) ) {
				new FormValidation({
					form: thisForm,
					// debug: true,
				});
			}
		} );
	}) ();


	/*** Placeholder labels - Un placeholder qui se tasse quand on écrit *************************************/
	// Requiert la classe sweet-form sur le formulaire
	// Requiert placeholder-label.js

	(function() {
		document.querySelectorAll('.has-placeholder-label input, .has-placeholder-label textarea').forEach( function( thisEl ) {
			if( functions.scriptsOk( thisEl ) ) {
				placeholderLabelUpdate( thisEl );

				[ 'load', 'focus', 'blur', 'change', 'input' ].forEach( function( eventName ) {
					thisEl.addEventListener( eventName, placeholderLabelUpdate );
				} );
			}
		} );
	}) ();


	/*** Fait afficher le nom du fichier dans les champs input stylisés ; drag-and-drop *********************/

	(function() {
		let inputs = document.querySelectorAll( '.hidden-file-input' );
		inputs.forEach( function( thisEl ) {
			if( functions.scriptsOk( thisEl ) ) {
				new PrettyFileInput({
					input: thisEl,
				});
			}
		});
	}) ();

	/*** Retour en haut **************************************************************************************/
	// Requiert window.smoothScroller (smooth-scroller.js), initialisé dans global-variables.js

	(function() {
		let backToTop = document.querySelector('.back-to-top');

		if( backToTop && functions.scriptsOk( backToTop ) ) {
			backToTop.addEventListener( 'click', function() {
				if( 1 == event.which ) {
					window.smoothScroller.scrollTo({ target: document.body });
				}
			} );
		}
	}) ();


	/*** Menu mobile maison **********************************************************************************/

	(function() {
		new MobileMenu({
			menuSel: '.mobile-menu',
			buttonSel: '.mobile-menu-button',
			// debug: true,
			dropdownParentLinkActive: false,

			// Si on agrandit la fenêtre dans ce media query, le menu se ferme automatiquement.
			closeMediaQuery: '(min-width: 1000px)',

			// viewportMax: { width: 699 },
		});
	}) ();


	/*** Menu de page à hash qui se met à jour avec le scroll *********************************************/
	// Requiert page-menu.js

	/*
	(function() {
		window.pageMenus = [];

		window.addEventListener( 'initialscrollend', function() {
			window.pageMenus.push( new PageMenu({
				menuSel: '.page-menu-desktop',
				keepLatest: true,
				debug: false,
			}) );

			// Pas d'update ? Ce menu n'existe pas. On l'enlève de l'array.
			if( 'function' != typeof window.pageMenus[ window.pageMenus.length - 1 ].update ) {
				window.pageMenus.splice( -1, 1 );
			}

			// Version mobile avec select (il faut les dédoubler parce que ça ne marche pas pareil)

			window.pageMenus.push( new PageMenu({
				menuSel: '.page-menu-mobile',
				keepLatest: true,
				type: 'select',
				// pour pas faire l'auto-hash et le Analytics en double avec le menu bureau
				autoHash: false,
				logAnalytics: false,
				debug: false,
			}) );

			// Pas d'update ? Ce menu n'existe pas. On l'enlève de l'array.
			if( 'function' != typeof window.pageMenus[ window.pageMenus.length - 1 ].update ) {
				window.pageMenus.splice( -1, 1 );
			}

			// Update au scroll
			window.addEventListener( 'scrollinterval', function() {
				window.pageMenus.forEach( function( thisMenu ) {
					thisMenu.update();
				} );
			} );
		} );
	}) ();
	*/


	/*** Recherche qui s'ouvre/ferme en cliquant sur la loupe ************************************************/
	// Requiert toggle-search-form.js

	/*
	(function() {
		if( 'undefined' !== typeof toggleSearchForm ) {
			document.querySelectorAll('.search-form .search-button').forEach( function( thisEl ) {
				thisEl.addEventListener( 'click', function() {
					if( 1 == event.which ) {
						if( ! thisEl.closest('.search-form').classList.contains('open') ) {
							toggleSearchForm( event );
						} else {
							thisEl.closest('form').submit();
						}
					}
				} );
			} );
		}
	}) ();
	*/


	/*** InfiniteScrollSimple - Version maison plus légère *************************************************/
	// Requiert infinite-scroll-simple.js
	// Requiert regenerator-runtime (Babel veut ça pour les fonctions async)
	// Voir le début du fichier pour des events sur lesquels vous hooker

	(function() {
		let infiniteContainers = document.querySelectorAll('.iss-container');

		infiniteContainers.forEach(function( thisEl ) {
			if( functions.scriptsOk( thisEl ) ) {
				new InfiniteScrollSimple({
					container: thisEl,
					useInner: true,
					animate: true,
					// debug: true,
				});
			}
		} );

	}) ();


	/*** LoadingButton - bouton pour AJAX avec preloader intégré *******************************************/

	(function() {
		let buttons = document.querySelectorAll('.loading-button');

		buttons.forEach(function( thisEl ) {
			if( functions.scriptsOk( thisEl ) ) {
				new LoadingButton({
					buttonEl: thisEl,
					debug: false,
				});

				// Le bouton est maintenant initialisé. On peut l'activer avec les méthodes :
				// buttonInstance.loadingStart() | buttonInstance.loadingEnd()
				// Fonctionnent aussi :
				// thisEl._loadingButton.loadingStart() | thisEl._loadingButton.loadingEnd()
			}
		} );
	}) ();



	/*** Infinite Scroll - Charger les prochains articles automatiquement **********************************/
	// Requiert infinite-scroll.pkgd.min.js
	/*
	(function() {
		let infiniteContainers = document.querySelectorAll('.infinite-scroll');

		infiniteContainers.forEach(function( thisEl ) {
			if( thisEl.parentNode.querySelector('.pagination .next') ) {
				let infiniteInstance = new InfiniteScroll(
					'.infinite-scroll',
					{
						path: '.pagination .next',
						append: '.infinite-scroll-element',
						prefill: false,
						history: false,
						// outlayer: outlayer,
						hideNav: '.pagination',
						status: '.infinite-scroll-status',
						debug: false,
					}
				);

				infiniteInstance.on( 'append', function( event, response, path, items ) {
					thisEl.parentNode.querySelector('.infinite-scroll-status').style.display = 'none';
					imgCheck();
					window.siteLazyLoad.update();
				} );
			}
		});
	}) ();
	*/


	/*** Dropdown de médias sociaux **************************************************************************/

	(function() {
		document.addEventListener( 'click', function( event ) {
			if( 1 == event.which ) {
				let buttonClicked = event.target.closest('.more-networks-button');
				let theContainer;
				let theDropdown;

				if( buttonClicked && functions.scriptsOk( buttonClicked ) ) {
					theContainer = buttonClicked.closest( '.more-networks' );
					theDropdown = theContainer.querySelector( '.more-networks-dropdown' );

					if( ! theContainer.classList.contains('animating') ) {
						theContainer.classList.add( 'animating' );

						if( ! theContainer.classList.contains( 'open' ) ) {
							theContainer.classList.add( 'open' );

							theDropdown.addEventListener( 'transitionend', function fadeInOver() {
								theContainer.classList.remove( 'animating' );
								theDropdown.removeEventListener( 'transitionend', fadeInOver );
							} );

						} else {
							theContainer.classList.remove( 'open' );

							theDropdown.addEventListener( 'transitionend', function fadeOutOver() {
								theContainer.classList.remove( 'animating' );
								theDropdown.removeEventListener( 'transitionend', fadeOutOver );
							} );
						}
					}
				}
			}
		} );
	}) ();


	/*** Sliders Glide ****************************************************************************************/
	// Requiert Glide (@glidejs/glide)
	// Ce slider n'a pas de fonction tout-en-un disponible.

	// large-slider-gallery
	(function() {
		let linkSliders = document.querySelectorAll('.page-block-large-slider-gallery .glide-slider');

		linkSliders.forEach( function( sliderEl ) {
			if( functions.scriptsOk( sliderEl ) ) {
				let sliderOptions = {
					type: 'slider',
					perView: 1,
					gap: 15,
					bound: true,
					rewind: true,
				};

				let sliderInstance = new Glide( sliderEl, sliderOptions );

				sliderInstance.on( 'move', function() {
					window.siteLazyLoad.update();
					sliderEl.classList.add( 'slider-animating' );
				} );

				sliderInstance.on( 'move.after', function() {
					window.siteLazyLoad.update();
					sliderEl.classList.remove( 'slider-animating' );
				} );

				sliderInstance.mount();
			}
		} );
	}) ();


	// testimonials-slider
	(function() {
		let linkSliders = document.querySelectorAll('.page-block-testimonials-slider .glide-slider');

		linkSliders.forEach( function( sliderEl ) {
			if( functions.scriptsOk( sliderEl ) ) {
				let sliderOptions = {
					type: 'carousel',
					perView: 1,
					gap: 50,
					bound: false,
					rewind: true,
					autoplay: 10000,
					hoverpause: true,
				};

				let sliderInstance = new Glide( sliderEl, sliderOptions );

				sliderInstance.on( 'move', function() {
					window.siteLazyLoad.update();
					sliderEl.classList.add( 'slider-animating' );
				} );

				sliderInstance.on( 'move.after', function() {
					window.siteLazyLoad.update();
					sliderEl.classList.remove( 'slider-animating' );
				} );

				sliderInstance.mount();
			}
		} );
	}) ();

	// Clients logo slider
	(function() {
		let linkSliders = document.querySelectorAll('.page-block-clients-list .glide-slider');

		linkSliders.forEach( function( sliderEl ) {
			if( functions.scriptsOk( sliderEl ) ) {
				let sliderOptions = {
					type: 'carousel',
					perView: 5,
					gap: 50,
					bound: false,
					rewind: true,
					autoplay: 4000,
					focusAt: 'center',
					hoverpause: true,
					breakpoints: {
						1400: {
							perView: 4
						},
						1000: {
							perView: 3
						},
						700: {
							perView: 2
						},
						500: {
							perView: 1
						}
					}
				};

				let sliderInstance = new Glide( sliderEl, sliderOptions );

				sliderInstance.on( 'move', function() {
					window.siteLazyLoad.update();
					sliderEl.classList.add( 'slider-animating' );
				} );

				sliderInstance.on( 'move.after', function() {
					window.siteLazyLoad.update();
					sliderEl.classList.remove( 'slider-animating' );
				} );

				sliderInstance.mount();
			}
		} );
	}) ();


	/*** Sliders Tiny Slider **********************************************************************************/
	// Requiert tns (tiny-slider)
	// Requiert initSliderTiny (tiny-slider-nubee.js)

	// (function() {
	// 	document.querySelectorAll('.tiny-slider').forEach( function( thisEl ) {
	// 		if( functions.scriptsOk( thisEl ) ) {
	// 			initSliderTiny( thisEl );
	// 		}
	// 	} );
	// }) ();


	/*** Empêcher la taille d'un élément de se réajuster une fois le chargement terminé ***********************/

	(function() {
		let lockedHeightEls = document.querySelectorAll('.lock-height-on-load');

		window.addEventListener( 'load', function() {
			lockedHeightEls.forEach( function( thisEl ) {
				if( functions.scriptsOk( thisEl ) ) {
					thisEl.style.height = thisEl.offsetHeight + 'px';
					thisEl.style.minHeight = 0;
					thisEl.style.maxHeight = 'none';
				}
			} );
		} );
	}) ();


	/*** Select qui redirigent quand on choisit une option ******************************************************/
	// Requiert functions.urlsAreSamePage
	// Requiert functions.arrayAllEqual

	(function() {
		let linkSelects = document.querySelectorAll('.link-select');

		linkSelects.forEach( function( thisSelect ) {
			if( functions.scriptsOk( thisSelect ) ) {
				[ 'change', 'keypress' ].forEach( function( eventName ) {
					thisSelect.addEventListener( eventName, function() {
						let selectedOption = thisSelect.options[ thisSelect.selectedIndex ];
						let targetUrl = selectedOption.dataset.url;

						if( targetUrl ) {
							if( /^#.+/.test( targetUrl ) ) {
								window.smoothScroller.scrollTo({ target: targetUrl });
							} else if( ! functions.urlsAreSamePage({ urls: targetUrl }) ) {
								window.location.href = targetUrl;
							}
						}
					} );
				} );
			}
		} );
	}) ();


	/*** Activation du doigt indicateur de scroll sur les blocs de statistiques appropriés **********************/
	// Requiert swipe-finger.js

	/*
	(function() {
		if( 'undefined' !== typeof SwipeFinger ) {
			let fingerWrappers = document.querySelectorAll('.swipe-finger-wrapper');
			fingerWrappers.forEach( function( thisWrapper ) {
				if( functions.scriptsOk( thisWrapper ) ) {
					let swipeFinger = new SwipeFinger({
						wrapper: thisWrapper,
						debug: false,
					});
				}
			} );
		}
	}) ();
	*/


	/*** Défile doucement au chargement d'une URL avec ancre ***************************************************/
	// Évènement "hashblocked" pour dire que le jump par défaut vient d'être bloqué
	// Évènement "initialscrollstart" pour dire qu'on s'apprête à faire le scroll
	// Évènement "initialscrollend" pour dire que la page est chargée ET l'ancre active est scrollée
	// S'il n'y a pas d'ancre (#) active, l'évènement est lancé dès le chargement.
	// Requiert window.smoothScroller (smooth-scroller.js), initialisé dans global-variables.js

	(function() {
		let origHash = window.location.hash;
		let hashBlockedEvent = new Event( 'hashblocked' );
		let scrollStartEvent = new Event( 'initialscrollstart' );
		let scrollEndEvent = new Event( 'initialscrollend' );

		if( origHash ) {
			let targetEl = document.querySelector( origHash );

			if( targetEl ) {
				window.addEventListener( 'hashblocked', function() {
					window.addEventListener( 'smoothscrollend', function initialScrollEnd() {
						window.dispatchEvent( scrollEndEvent );
						window.removeEventListener( 'smoothscrollend', initialScrollEnd );
					} );

					window.dispatchEvent( scrollStartEvent );

					window.smoothScroller.scrollTo({
						target: origHash,
						historyState: history.state,
						historyAction: 'replace',
						// debug: true,
					});
				} );


				// Blocage du jump du navigateur par défaut
				setTimeout( function() {
					functions.changeHashWithoutScrolling( null, history.state, 'replace' );
					window.scrollTo( 0, 0 );
					window.dispatchEvent( hashBlockedEvent );
				}, 1 );
			} else {
				window.dispatchEvent( scrollEndEvent );
			}
		} else {
			window.dispatchEvent( scrollEndEvent );
		}
	}) ();


	/*** Donner la même hauteur à un groupe d'éléments **********************************************************/

	(function() {
		window.addEventListener( 'load', function() {
			let sameHeightGroups = document.querySelectorAll('.same-height-group');

			sameHeightGroups.forEach( function( thisGroup ) {
				if( functions.scriptsOk( thisGroup ) ) {
					let minWidth = thisGroup.dataset.sameHeightMinWidth || 0;
					let sameHeightEls = thisGroup.querySelectorAll('.same-height');
					let highestHeight = 0;

					if( window.vWidth >= minWidth ) {
						sameHeightEls.forEach( function( thisEl ) {
							highestHeight = Math.max( thisEl.clientHeight, highestHeight );
						} );

						sameHeightEls.forEach( function( thisEl ) {
							thisEl.style.minHeight = highestHeight + 'px';
						} );
					}
				}
			} );
		} );
	}) ();


	/*** smoothFadeIn - effet d'apparition lorsque dans le viewport *****************************************/
	// Requiert functions.smoothFadeIn
	// Options dans general-styles.scss

	(function() {
		let fadeInItems = document.querySelectorAll('.smooth-fade-in');

		fadeInItems.forEach( function( thisEl ) {
			functions.smoothFadeIn( thisEl );

			window.addEventListener( 'scrollinterval', function() {
				functions.smoothFadeIn( thisEl );
			} );
		} );
	}) ();



	/*** Onglets ********************************************************************************************/
	// Requiert tabs.js

	/*
	(function() {
		let tabsContainers = document.querySelectorAll('.tabs-block');

		tabsContainers.forEach( function( thisEl ) {
			new Tabs({
				container: thisEl,
				debug: false,
			});
		} );
	}) ();
	*/



	/*** Tooltips tippy *************************************************************************************/
	// Requiert tippy.js
	/*
	(function() {
		tippy( '[data-tippy-content]', {
			theme: 'light',
			flipBehavior: [ 'bottom', 'left' ],
		} );
	}) ();
	*/


	/*** Lightbox et popups avec GLightbox ******************************************************************/
	// Requiert GLightbox (glightbox)
	// Requiert glightbox-nubee.js

	(function() {
		// Éléments seuls.
		let lightboxEls = document.querySelectorAll('.lightbox');
		lightboxEls.forEach( function( thisEl ) {
			if( functions.scriptsOk( thisEl ) ) {
				new GLightboxNubee({
					links: thisEl,
					// debug: true,
				});
			}
		} );

		// Galeries. (Attention de donner une classe différente à chaque galerie de la page.)
		// Utilisez data-lightbox-element-class="ma-classe" sur .lightbox-gallery pour l'identifier.
		let lightboxGals = document.querySelectorAll('.lightbox-gallery');
		lightboxGals.forEach( function( thisGal ) {
			if( functions.scriptsOk( thisGal ) ) {
				new GLightboxNubee({
					galleryParent: thisGal,
					// debug: true,
				});
			}
		} );

		// On peut maintenant accéder aux instances à partir des liens ou du parent de la galerie :
		// <un lien lightbox ou un div galerie>._GLightboxNubee
		// <un lien lightbox ou un div galerie>._GLightbox
	}) ();


	/*** Boutons +/- des input type number *******************************************************************/
	// Requiert NumberInput (number-input.js)

	/*
	(function() {
		let inputs = document.querySelectorAll('.input-number');

		inputs.forEach( function( thisInput ) {
			let inputInstance = new NumberInput({
				input: thisInput,
			});
		} );
	}) ();
	*/


	/*** Datepickers avec flatpickr ***************************************************************************/
	// Requiert initFlatpickr (init-flatpickr.js)
	// Requiert flatpickr
	/*
	(function() {
		let dateFields = document.querySelectorAll('.input-date');

		dateFields.forEach( function( thisField ) {
			initFlatpickr( thisField );
		} );
	}) ();



	/*** fake-sticky ******************************************************************************************/
	// Requiert fake-sticky.js
	// Pour quand le CSS display: sticky; marche pas bien.

	(function() {
		let fakeStickies = document.querySelectorAll('.fake-sticky');

		fakeStickies.forEach( function( thisEl ) {
			if( functions.scriptsOk( thisEl ) ) {
				let fakeStickyArgs = {
					element: thisEl,
					// debug: true,
					minWidth: thisEl.dataset.fakeStickyMinWidth || null,
					maxWidth: thisEl.dataset.fakeStickyMaxWidth || null,
					minHeight: thisEl.dataset.fakeStickyMinHeight || null,
					maxHeight: thisEl.dataset.fakeStickyMaxHeight || null,

					// mediaQueries à [] = meilleure performance ; aucun ajustement au resize
					// Définir des mediaQueries = bonne performance ; ajustement aux mediaQueries seulement
					// mediaQueries à null = ajustement au pixel près
					mediaQueries: [
						'(min-height: 600px)', // height!
					],
				};

				fakeStickyArgs.minHeight = thisEl.dataset.fakeStickyMinHeight || null;

				new FakeSticky( fakeStickyArgs );
			}
		} );
	}) ();


	/*** Formulaire de recherche - changement de l'URL cible quand on choisit une option au select *************/
	// Requiert multi-action-form.js

	(function() {
		let multiActionForms = document.querySelectorAll('.multi-action-form');

		multiActionForms.forEach( function( thisEl ) {
			if( functions.scriptsOk( thisEl ) ) {
				new MultiActionForm({
					form: thisEl,
					sourceElSel: '.multi-action-form-url',
					debug: true,
				});
			}
		} );
	}) ();

	/*** Accordéons pour bloc FAQ *************/

	(function() {

		var acc = document.getElementsByClassName("faq-question");
		var panel = document.getElementsByClassName('faq-answer');

		for (var i = 0; i < acc.length; i++) {
			acc[i].onclick = function() {
				var setClasses = !this.classList.contains('active');
				setClass(acc, 'active', 'remove');
				setClass(panel, 'show', 'remove');

				if (setClasses) {
					this.classList.toggle("active");
					this.nextElementSibling.classList.toggle("show");
				}
			}
		}

		function setClass(els, className, fnName) {
			for (var i = 0; i < els.length; i++) {
				els[i].classList[fnName](className);
			}
		}

	}) ();

}) ();