function LoadingButton( args ) {
	let that = this;

	args = args || {};
	args.debug = args.debug || false;
	args.loadingClass = args.loadingClass || 'loading';

	this.args = args;

	this.init = function() {
		that.button = that.args.buttonEl || null;
		that.loading = false;
	};

	this.loadingStart = function() {
		that.loading = true;
		that.button.disabled = true;
		that.button.classList.add( that.args.loadingClass );
	};

	this.loadingEnd = function() {
		that.loading = false;
		that.button.disabled = false;
		that.button.classList.remove( that.args.loadingClass );
	};

	this.init();

	if( ! this.button ) {
		return [];
	} else {
		this.button._loadingButton = this;
	}
}

export default LoadingButton;