function MultiActionForm( args ) {
	let that = this;

	args = args || {};
	args.sourceElSel = args.sourceElSel || '.multi-action-form-url';

	args.debug = args.debug || false;

	this.args = args;

	this.init = function() {
		that.url = null;
		that.option = null;
		that.form = that.args.form || null;

		if( ! that.form ) {
			if( that.args.debug ) console.error( "multi-action-form: No form given. Aborting." );
			return;
		}

		that.sourceEl = that.form.querySelector( that.args.sourceElSel );

		if( ! that.sourceEl ) {
			if( that.args.debug ) {
				console.error( "multi-action-form: No source element found in the following form for the following selector. Aborting." );
				console.log( that.form, that.args.sourceElSel );
			}
			return;
		}

		that.updateEverything();
		that.addEventListeners();
	};

	that.updateOption = function() {
		if( 'SELECT' == that.sourceEl.tagName ) {
			that.option = that.sourceEl.options[ that.sourceEl.selectedIndex ];
		} else {
			that.option = that.sourceEl;
		}
	};

	that.updateUrl = function() {
		if( that.option ) {
			that.url = that.option.getAttribute( 'data-url' );
		} else {
			that.url = that.sourceEl.value;
		}
	};

	that.updateEverything = function() {
		that.updateOption();
		that.updateUrl();
		that.form.action = that.url;
	};

	that.addEventListeners = function() {
		[ 'change', 'input' ].forEach( function( eventName ) {
			that.sourceEl.addEventListener( eventName, function() {
				that.updateEverything();
			} );
		} );
	};

	this.init();

	if( ! this.form ) {
		return [];
	} else {
		this.form._MultiActionForm = this;
	}

}

export default MultiActionForm;